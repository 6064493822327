// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="&quot;Mit der Wärmepumpe die Umwelt schonen&quot;"
        meta={[
          { name: 'description', content: 'Familie Heim erzählt, warum sie sich für eine Wärmepumpe entschieden hat und welche Vorteile sie bietet. Gleich reinschauen!' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='45dEfY7eLX7lRJ5hGPuIDH-5FbkWNpdve37ZvJZoximwJ'
          id='familie-muster-testlingen-so'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "5FbkWNpdve37ZvJZoximwJ",
    "updatedAt": "2021-01-13T09:35:33.008Z",
    "title": "FAMILIE HEIM, HOFSTETTEN SO",
    "size": "Groß",
    "teaser": "Zwei Jahre lang setzte sich die Familie Heim mit verschiedenen Heizsystemen auseinander. Ihr Ziel: eine möglichst effiziente und umweltschonende Form der Heizenergie-Erzeugung für ihr Einfamilienhaus zu finden. Silvio Heim erfuhr vom «Tag der offenen Wärmepumpe» der Heizungsmacher AG. Danach war für ihn alles klar.",
    "image": {
        "description": "Ein Bild von der Sicht auf die Photovoltaik-Anlage der Familie Heim",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/3iiX6sKxEQGUMCkWSO80qi/8f30be31f19b5f2d9d089c622d5e400c/1---PV-Anlage-formatiert.PNG"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-muster-testlingen-so"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='45dEfY7eLX7lRJ5hGPuIDH-4ahBsAqbdjvPXjaKqKMmN8'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "4ahBsAqbdjvPXjaKqKMmN8",
    "updatedAt": "2023-08-16T08:31:16.188Z",
    "title": "«Wir wollten die Umwelt so gut wie möglich schonen»",
    "steps": [
        {
            "type": "howToStep",
            "id": "56kR0sspcKh2vh4zWFraEO",
            "updatedAt": "2023-08-18T06:46:22.329Z",
            "title": "Wie Silvio Heim auf Heizungsmacher aufmerksam wurde",
            "description": "<p>Dass es keine umweltschonendere Form der Heizenergie-Erzeugung als die <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a> gibt, hatte Silvio Heim schon bald festgestellt. Auf vielen Websites eignete er sich ein grosses Wissen über die Vor- und Nachteile aller Heizsystemen. Doch die Arbeit begann erst danach so richtig, sagt er: «Es gibt so viele Wärmepumpen-Anbieter. Es war wirklich schwer, einen Überblick zu gewinnen. <strong>Also beschlossen meine Frau und ich, Unterstützung von erfahrenen und <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">unabhängigen Wärmepumpen-Spezialisten</a> zu holen, die Komplettlösungen anbieten.»</strong> So stiessen sie auf die Heizungsmacher AG. Die Firma führt vor Ort in den Häusern ihrer Kunden immer wieder «Tage der offenen Wärmepumpe» durch. Dabei erleben die Besucher die <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-waermepumpe/\">Funktionsweise der Wärmepumpen</a> live. Familie Heim nahm teil – und war begeistert.</p>\n",
            "image": {
                "description": "Ein Bild vom Haus-Vorplatz der Familie Heim",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1xCmnCodDkf59DV6xSZEUu/b7c0aef00c52d546c0f0f7901a7bbe1b/3_-_Vorplatz_Haus_Familie_Heim.jpg"
            }
        },
        {
            "type": "howToStep",
            "id": "oKBfHgIXx01UdEU9rIDxZ",
            "updatedAt": "2023-08-16T08:25:41.176Z",
            "title": "Das perfekte Duo: Synergie von Wärmepumpe und Photovoltaik",
            "description": "<p>Am Anfang seiner Recherche hatte Silvio Heim wegen der ökologischen Eigenschaften noch eine Holzpelletheizung im Auge. Für noch mehr Unabhängigkeit war auch die Produktion von eigenem Strom eine Option. Schliesslich stellte Silvio Heim im Verlaufe seiner Recherchen fest: <strong>«Die beste Lösung für die Eigenstromproduktion ist die <a href=\"https://www.heizungsmacher.ch/wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo/\">Kombination einer Photovoltaikanlage mit einer Wärmepumpe</a>.»</strong> Die Experten der Heizungsmacher AG bestätigten dies. Silvio Heim: «Ich hatte die richtigen Experten gefunden: Die Heizungsmacher AG bot uns beides im hocheffizienten Paket an.»</p>\n<p>Ursprünglich dachte Familie Heim daran, die beiden neuen Anlagen zeitlich gestaffelt einzubauen. Die Kundenberater der Heizungsmacher AG zeigten aber auf, dass die Familie vom wirtschaftlichen Duo nur vollständig profitiert, wenn beide Anlagen gleichzeitig vom selben Anbieter gebaut werden. Denn das garantiert, dass das <a href=\"https://www.heizungsmacher.ch/waermepumpe-photovoltaik/\">Zusammenspiel von Wärmepumpe und Photovoltaik</a> einwandfrei funktioniert.</p>\n",
            "image": {
                "description": "Heizungsmacher Icon Highlight Wärmepumpe",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/56if0gOPlmi68QkmmQSYOu/7d69775fa63648d9546589cf1ff41624/2_-_Bild_PV_Anlage.jpg"
            }
        },
        {
            "type": "howToStep",
            "id": "268D4Bs70LIXECeYTYowaY",
            "updatedAt": "2023-08-16T08:30:27.059Z",
            "title": "Unabhängiger vom Netzstrom",
            "description": "<p>Silvio Heim ist glücklich: «Wir haben vom Know-how der Heizungsmacher stark profitiert, weil beide Anlagen optimal aufeinander abgestimmt sind.» Ein Aspekt hat ihn besonders beeindruckt: <strong>«Der ganze Umbau der beiden Anlagen war innerhalb einer Woche abgeschlossen.»</strong> Von Anfang an liess sich die Synergie der beiden ökologischen Anlagen nutzen.</p>\n<p>Eine Wärmepumpe allein ist bereits sehr umweltschonend, erklärt Thomas Schweingruber, Geschäftsführer und Heizungsexperte bei der Heizungsmacher AG: «Doch mit der zusätzlichen Photovoltaikanlage hat Familie Heim einen noch grösseren Schritt in die Unabhängigkeit gemacht. Die Wärmepumpe gewinnt bis zu 80 Prozent erneuerbare Energie aus der Umwelt – beispielsweise aus der Luft, dem Erdreich oder dem Grundwasser. Bei der Familie Heim war es eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-aussenaufstellung/\">aussen aufgestellte Luft/Wasser-Wärmepumpe</a> von <a href=\"https://www.heizungsmacher.ch/nibe/\">NIBE</a>. Mit der zusätzlichen Photovoltaikanlage kann man ausserdem die Sonnenenergie nutzen.» Eine Photovoltaikanlage speichert das Licht, das auf die Solarzellen fällt. Dieses Licht wandelt ein Wechselrichter in Strom um, der sich im Eigenheim nutzen lässt. <strong>Dank der Lösung von Heizungsmacher AG ist Familie Heim heute unabhängiger vom Netzstrom.</strong></p>\n",
            "image": {
                "description": "Ein Bild vom Umbau der beiden Anladen (Photovoltaik und Wärmepumpe)",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6v0fBQq3wQoAeM0ig8kkqi/d4ad9834a75f8d8c5c22277ceda13636/4_-_Bild_von_der_Bauphase.jpg"
            }
        },
        {
            "type": "howToStep",
            "id": "2GyobzNTnwtBBG3PmE9ct4",
            "updatedAt": "2023-08-16T08:31:09.413Z",
            "title": "Adieu Ölheizung – hallo Fitnessraum",
            "description": "<p>Herr Heim schmunzelt: «Jetzt suche ich noch nach Ideen für den gewonnenen Raum im Keller. Wir können schon vieles verstauen, was vorher immer im Weg stand. Aber bald einmal möchten wir einen tollen Raum einrichten.» Er und seine Frau sind bereits bei den nächsten Recherchen und Überlegungen. <strong>Vielleicht zusätzlich zur Sauna ein Fitnessbereich? Oder doch lieber ein Büro?</strong> «Wir lassen uns wieder Zeit», lacht Silvio Heim.</p>\n<p>Seine Familie fühlte sich von Anfang an wohl mit den Leuten der Heizungsmacher AG. Der stolze Einfamilienhausbesitzer sagt: «Wir wurden während des gesamten Prozesses kompetent betreut. Die <a href=\"https://www.heizungsmacher.ch/ueber-uns/\">Fachleute</a> der Firma standen uns vom ersten Beratungsgespräch bis nach der Inbetriebnahme zur Seite. <strong>Ein solch lückenloser Service ist für mich nicht selbstverständlich.»</strong></p>\n<p>Besonders gut gefallen hatte der Familie, dass sie vor dem Umbau eine detaillierte Bauplanung mit allen Terminen und Bauphasen erhalten hatte. <strong>«Und tatsächlich – alle Termine wurden eingehalten»</strong>, freut sich Silvio Heim. Sein Fazit zum Schluss des Gesprächs fällt eindeutig aus: <strong>«Es war die beste Entscheidung, den kompletten Umbau der Heizung und der Photovoltaikanlage den Heizungsmachern zu überlassen.»</strong></p>\n",
            "image": {
                "description": "Ein Bild von der Wärmepumpe im Garten und der installierten Geräten im Keller",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7EIlnad6daeAe4wy0YKAmK/2692f19247c7c7182d7b948dd63756b9/5_-_Bild_von_installierten_Ger__ten.jpg"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='45dEfY7eLX7lRJ5hGPuIDH-3k8EjtG8Zoz4NDPQHARnwC'
          id='feedback-familie-heim'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "3k8EjtG8Zoz4NDPQHARnwC",
    "updatedAt": "2023-08-16T08:31:55.499Z",
    "title": "Feedback Familie Heim",
    "anchorName": "feedback-familie-heim",
    "text": "<p><em>Seit zwei Jahren läuft unsere Nibe Wärmepumpe mit Solaranlage und Batteriespeicher vollkommen problemlos. Mit dieser Kombination sind wir von März bis Oktober vollkommen autark. Die Entscheidung, von der Ölheizung zur Luft-Wärmepumpe umzusteigen, haben wir nie bereut und wir sind mit der Anlage vollkommen glücklich!</em></p>\n<p><em>Unsere Heizkosten haben sich mit der neuen Anlage zudem massiv verringert.\nAusserdem hat sich im Haus das „Wohlfühlgefühl“ positiv verändert, kein Lärm mehr und kein Ölgeschmack. Die Wärmepumpe mit Solaranlage ist nachhaltig, leise und umweltverträglich. Unser Energielieferant hat die Einspeisevergütung mehr als verdoppelt – was in der momentanen Energiekrise ein zusätzlicher Gewinn ist und zeigt, dass unser Entscheid folgerichtig und zukunftsweisend war.</em></p>\n<p><em>Abschliessend möchten wir sagen, dass wir mit den Heizungsmachern, insbesondere Frau Pfluger, Herrn Weber und Herrn Schweingruber, sehr zufrieden waren und die ganze Installation problemlos verlief. Auch nach Fertigstellung der Anlage waren die Heizungsmacher bei Fragen zur Anlage und bei unserer Netzumstellung immer hilfsbereit und prompt zur Stelle. Darum vergeben wir gerne fünf Sterne!</em></p>\n<p>Heizungsmacher-Team: <em>Sehr geehrter Herr Heim. Vielen Dank für Ihre Rückmeldung. Es freut uns zu hören, dass Sie mit unserem Service als auch den Produkten zufrieden waren. Wir wünschen Ihnen weiterhin viel Freude damit.</em></p>\n",
    "image": {
        "description": "Kundenfeedback: 5 Sterne-Bewertung",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/7dFyVjY2BukXpteLJih95X/047c09b3094ac7fb446dc6015dcddd65/5_Sterne-Bewertung.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='45dEfY7eLX7lRJ5hGPuIDH-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
